import { routerAbi } from "@/ABIs/Router";
import { useToast } from "@/components/ui/use-toast";
import { Contracts } from "@/ContractsConfig";
import { useEffect } from "react";
import {
  ContractFunctionArgs,
  ContractFunctionExecutionError,
  ContractFunctionName,
  TransactionReceipt,
} from "viem";
import { useWaitForTransactionReceipt, useWriteContract } from "wagmi";

type FunctionNameType = ContractFunctionName<
  typeof routerAbi,
  "nonpayable" | "payable"
>;

const useContract = <
  Fn extends FunctionNameType,
  Args extends ContractFunctionArgs<
    typeof routerAbi,
    "nonpayable" | "payable",
    Fn
  >,
>({
  toastTitle,
  onSubmit,
  onConfirm,
  onConfirmDelayed,
}: {
  toastTitle: string;
  onSubmit?: (hash: `0x${string}`) => void;
  onConfirm?: (data: TransactionReceipt) => void;
  onConfirmDelayed?: (data: TransactionReceipt) => void;
}) => {
  const {
    writeContractAsync,
    data: txnHash,
    error,
    isPending: isSubmitting,
  } = useWriteContract();
  const {
    isLoading: isConfirming,
    isSuccess: isConfirmed,
    data,
  } = useWaitForTransactionReceipt({ hash: txnHash });
  const { toast } = useToast();

  const callContract = async (functionName: Fn, args: Args, value?: bigint) => {
    toast({
      title: toastTitle,
      description: (
        <div className="flex items-baseline">
          Please confirm the transaction in your wallet
        </div>
      ),
    });
    const hash = await writeContractAsync({
      abi: routerAbi,
      address: Contracts.router,
      functionName: functionName as FunctionNameType,
      // @ts-expect-error - this is fine
      args: args as Args,
      // @ts-expect-error - this is fine
      value: value,
    });
    if (onSubmit) onSubmit(hash);
    return hash;
  };

  useEffect(() => {
    if (error) {
      console.log({ ...error });
      const typedError = error as ContractFunctionExecutionError;
      let message = "";
      if (typedError.metaMessages && typedError.metaMessages.length > 2) {
        message = typedError.metaMessages[1].trim();
        if (message.startsWith("(") && message.endsWith(")")) {
          message = message.slice(1, -1);
        }
      }
      if (!message) {
        message = typedError.shortMessage || error.message;
      }
      toast({
        variant: "destructive",
        title: "Something went wrong",
        description: message,
      });
    }
  }, [error, toast]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined;
    if (isConfirming) {
      toast({
        title: toastTitle,
        description: (
          <div className="flex items-baseline">
            Confirming transaction. Please wait.
          </div>
        ),
      });
    } else if (isConfirmed) {
      if (onConfirm) onConfirm(data);
      if (onConfirmDelayed) {
        timeoutId = setTimeout(() => {
          onConfirmDelayed(data);
        }, 2000);
      }
      toast({
        title: toastTitle,
        description: (
          <div className="flex items-baseline">
            Transaction confirmed.
            <br />
            {txnHash}
          </div>
        ),
      });
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [isConfirmed, isConfirming, data]);

  return {
    callContract,
    isSubmitting,
    isConfirming,
    isConfirmed,
    toast,
    txnHash,
    data,
  };
};

export default useContract;
