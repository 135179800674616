import { connectorsForWallets } from "@rainbow-me/rainbowkit";
import {
  coinbaseWallet,
  metaMaskWallet,
  rainbowWallet,
  walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { QueryClient } from "@tanstack/react-query";
import { createConfig } from "@wagmi/core";
import { base } from "@wagmi/core/chains";
import { createPublicClient, defineChain, http } from "viem";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      retryDelay: 1000,
    },
  },
});

export const customAnvil = defineChain({
  id: +import.meta.env.VITE_CHAIN_ID,
  name: "Anvil",
  nativeCurrency: {
    decimals: 18,
    name: "Ether",
    symbol: "ETH",
  },
  rpcUrls: {
    default: {
      http: [import.meta.env.VITE_RPC_URL],
      // webSocket: [import.meta.env.VITE_RPC_WS_URL],
    },
  },
});

// const oldConfig = getDefaultConfig({
//   appName: "Stonk.social",
//   projectId: "YOUR_PROJECT_ID",
//   chains: [import.meta.env.VITE_MODE === "mainnet" ? base : customAnvil],
//   ssr: false, // If your dApp uses server side rendering (SSR)
// });

export const publicClient = createPublicClient({
  chain: import.meta.env.VITE_MODE === "mainnet" ? base : customAnvil,
  transport: http(),
});

const connectors = connectorsForWallets(
  [
    {
      groupName: "Recommended",
      wallets: [
        metaMaskWallet,
        walletConnectWallet,
        coinbaseWallet,
        rainbowWallet,
      ],
    },
  ],
  {
    appName: "Stonk.social",
    projectId: import.meta.env.VITE_PROJECT_ID,
  }
);

export const config = createConfig({
  chains: [import.meta.env.VITE_MODE === "mainnet" ? base : customAnvil],
  connectors,
  transports: {
    [base.id]: http(),
    [customAnvil.id]: http(),
  },
});
